import store from './store'
import axios from './axios'

export function catchEveryRequest({ error } = {}) {
	if (error.response) {
		var response = error.response
		var status = response.status
		//var data = response.data

		if (status === 403) {
			location.href = '/admin/login'
		} else {
			if (process.env.NODE_ENV === 'development') {
				// eslint-disable-next-line
				console.error(error)
			} else {
				location.reload()
			}
		}
	}

	return response
}

export function getProfile(callback = null) {
	axios
		.get('/admin/profile')
		.then((response) => {
			let data = response.data
			store.state.admin = data.admin

			if (callback) {
				callback(data)
			}
		})
		.catch((error) => {
			if (error.response && error.response.status === 403) {
				store.state.admin = null
				if (callback) {
					callback(null)
				}
			} else {
				catchEveryRequest({ error })
			}
		})
}

export function loadCities(callback = null) {
	axios
		.get('/admin/cities/json')
		.then((response) => {
			let data = response.data

			store.state.cities = data.cities
		})
		.catch((error) => {
			catchEveryRequest({ error })
		})
		.finally(() => {
			if (callback) {
				callback()
			}
		})
}
export function loadCityData(cityName, callback = null) {
	axios
		.get('/admin/cities/json/?name=' + cityName)
		.then(async (response) => {
			let cityData = response.data

			if (callback) {
				callback(cityData)
			}
		})
		.catch((error) => {
			if (error.response && error.response.status === 404) {
				if (callback) {
					callback(null)
				}
			} else {
				catchEveryRequest({ error })
			}
		})
}

export function initSavedScroll() {
	if (store.state.savedPosition) {
		var scrollXPosition = store.state.savedPosition.x
		var scrollYPosition = store.state.savedPosition.y

		setTimeout(() => {
			window.scrollTo(scrollXPosition, scrollYPosition)
			store.state.savedPosition = null
		})
	}
}
