import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const state = {
	admin: null,
	cities: [],
	i18n: {
		cities: {},
	},
}

const store = new Vuex.Store({
	state: Object.assign(
		{
			language: null,
			csrf: null,
			server: {
				baseURL: 'https://tezdet.com',
			},
			savedPosition: null,
			ordersTableScrollPosition: {},
		},
		state
	),
})

export function resetOnLogout() {
	for (let name in state) {
		store.state[name] = state[name]
	}
}

export default store
