// Lodash
window._ = require('lodash')

// jQuery
window.$ = require('jquery')
let jconfirm = require('jquery-confirm') // eslint-disable-line
let daterangepicker = require('daterangepicker') // eslint-disable-line

// Bootstrap
import 'bootstrap'

// Style
import './assets/style.scss'

// Vue
import Vue from 'vue'

// Router
import router from './router'
// Store
import store from './store'

// Axios
import axios from './axios'
Vue.prototype.$axios = axios

// I18n
import i18n from './i18n'
import getUserLocale from 'get-user-locale'

// FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
// Add all icons to the library so you can use it in your page
library.add(fas, far, fab)
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
Vue.component('font-awesome-icon', FontAwesomeIcon)

// App component
import App from './App.vue'

Vue.config.productionTip = false

const app = new Vue({
	router,
	store,
	i18n,

	render: (h) => h(App),
})

app.$i18n.locale = getUserLocale() || 'en'
app.$i18n.locale = app.$i18n.locale.substr(0, 2)
store.state.language = app.$i18n.locale

axios.defaults.headers['Accept-Language'] = store.state.language

var messages = require('./messages').default
messages = messages[app.$i18n.locale]
app.$i18n.mergeLocaleMessage(app.$i18n.locale, messages)
app.$mount('#app')

// Prevent third-party websites.
if (
	process.env.NODE_ENV !== 'development' &&
	location.hostname !== 'tezdet.com'
) {
	location.href = 'https://tezdet.com'
}
