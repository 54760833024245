<template>
	<div class="home-page">
		<navbar />

		<div class="content">
			<div class="container">
				<div class="logo">
					<p>
						<img src="/images/logo.png" alt="Logo" width="50" height="50" />
					</p>
					<h3 class="text-center">
						{{ $t('mainTitle') }}
					</h3>
				</div>

				<hr />

				<h4 class="text-center mb-4">
					{{ $t('downloadTheApp') }}
				</h4>

				<div class="store">
					<div>
						<p>App Store</p>
						<a
							href="https://apps.apple.com/us/app/tezdet/id1593238278"
							class="appstore"
						>
							<img
								:src="
									'https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/' +
									$store.state.language +
									'?releaseDate=1638230400&h=2de260d7b19fe2b153089b6a6c173fee'
								"
								alt="Download on the App Store"
							/>
						</a>
					</div>

					<div>
						<p>Play Market</p>
						<a
							href="https://play.google.com/store/apps/details?id=com.tezdet"
							class="playstore"
						>
							<img
								:src="
									'https://play.google.com/intl/en_us/badges/static/images/badges/' +
									$store.state.language +
									'_badge_web_generic.png'
								"
								alt="Download from Play Market"
							/>
						</a>
					</div>
				</div>

				<hr />

				<h4 class="text-center mb-4">
					{{ $t('socialNetworks') }}
				</h4>

				<div class="socials text-center">
					<a
						href="https://instagram.com/tezdetapp"
						class="instagram"
						target="__blank"
					>
						<font-awesome-icon icon="fa-brands fa-instagram" />
					</a>
					<a
						href="https://facebook.com/tezdetapp"
						class="facebook"
						target="__blank"
					>
						<font-awesome-icon icon="fa-brands fa-facebook" />
					</a>
					<a
						href="https://tiktok.com/@tezdetapp"
						class="tiktok"
						target="__blank"
					>
						<font-awesome-icon icon="fa-brands fa-tiktok" />
					</a>
					<a href="https://vk.com/tezdetapp" class="vk" target="__blank">
						<font-awesome-icon icon="fa-brands fa-vk" />
					</a>
					<a
						href="https://twitter.com/tezdetapp"
						class="twitter"
						target="__blank"
					>
						<font-awesome-icon icon="fa-brands fa-twitter" />
					</a>
				</div>

				<footer>Tezdet &copy; 2021-{{ new Date().getFullYear() }}</footer>
			</div>
		</div>
	</div>
</template>

<script>
import Navbar from '../components/Navbar'

export default {
	components: {
		Navbar,
	},

	data() {
		return {}
	},

	created() {
		document.title = this.$t((this.$i18n.title = 'mainTitle'))
	},
}
</script>

<style lang="scss">
.logo {
	margin-top: 10px;
	margin-bottom: 30px;

	img {
		border-radius: 5px;
		width: 70px;
		height: 70px;
	}

	p {
		text-align: center;

		b {
			font-size: 22px;
		}
	}
}

.store {
	div {
		text-align: center;
		margin-bottom: 25px;
	}

	p {
		margin-bottom: 5px;
		font-weight: bold;
	}

	.playstore {
		margin: -8px;

		img {
			width: 160px;
			margin-top: -8px;
			margin-bottom: -8px;
		}
	}

	.appstore {
		img {
			width: 130px;
		}
	}
}

.socials {
	a {
		margin-left: 5px;
		margin-right: 5px;
	}
}

footer {
	padding-top: 5px;
	text-align: center;
	color: gray;
	font-size: 12px;
	margin-top: 25px;
}
</style>
