<template>
	<div id="app">
		<router-view :key="$route.fullPath" v-if="isLoaded" />
	</div>
</template>

<script>
import axios from './axios'
import store from './store'
import { catchEveryRequest, getProfile, loadCities } from './helpers'

export default {
	data() {
		return {
			isLoaded: false,
		}
	},

	created() {
		axios
			.get('/csrf-token')
			.then((response) => {
				let data = response.data

				store.state.csrf = data._csrf
				axios.defaults.headers['X-CSRF-TOKEN'] = store.state.csrf

				axios
					.get(`/app/i18n/cities`)
					.then((response) => {
						let data = response.data

						store.state.i18n.cities = data.cities

						getProfile(() => {
							if (store.state.admin) {
								loadCities(() => {
									this.isLoaded = true
								})
							} else {
								this.isLoaded = true
							}
						})
					})
					.catch((error) => {
						catchEveryRequest({ error })
					})
			})
			.catch((error) => {
				this.isLoaded = true
				catchEveryRequest({ error })
			})
	},

	methods: {},
}
</script>
